import React from 'react'
import { getPaymentStats } from '../../utils/actions'

class Stats extends React.Component {
  state = {}

  componentDidMount () {
    getPaymentStats().then((json) => {
      this.setState({ stats: json })
    })
  }

  render () {
    const { stats } = this.state
    return (
      <div>
        <h1>Suma aktywnych: {(stats && stats.total_sum + ' zł') || ''}</h1>
        <h1>Ilość aktywnych: {(stats && stats.total_count) || ''}</h1>
        <h1>Suma szacowanych na ten miesiąc: {(stats && stats.total_sum_this_month + ' zł') || ''}</h1>
        <h1>Suma szacowanych na ten miesiąc z churn rate 16%: {(stats && stats.total_sum_this_month_with_churn + ' zł') || ''}</h1>
        <h1>Suma oczekujących na spłynięcie w tym miesiącu: {(stats && stats.pending_subs + ' zł') || ''}</h1>
        <h1>Suma nowych subów w tym miesiącu: {(stats && stats.new_subs_sum + ' zł') || ''}</h1>
        {stats?.subs && Object.keys(stats?.subs).map(amount =>
          <h1>{amount} zł x {stats?.subs[amount].count} = {stats?.subs[amount].sum} zł</h1>
        )}
      </div>
    )
  }
}

export default Stats
